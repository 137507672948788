var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-discussion-page"
    }
  }, [_c('div', {
    staticClass: "row",
    attrs: {
      "data-cy": "messages"
    }
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5 col-12"
  }, [_c('h4', {
    staticClass: "mb-4"
  }, [_vm._v("Messages")]), _vm.$permissions.write('asset', _vm.parent) ? _c('button', {
    staticClass: "btn btn-primary btn-block mb-4",
    attrs: {
      "data-cy": "new-message-btn"
    },
    on: {
      "click": function ($event) {
        _vm.showNewMessage = true;
      }
    }
  }, [_vm._v(" New Message ")]) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "form-control border-0",
    attrs: {
      "type": "text",
      "placeholder": "Search Messages.."
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }), _vm._m(0)])]), _vm.filteredMessages.length > 0 ? _c('div', [_vm._m(1), _c('MessagesInbox', {
    staticClass: "mb-3",
    attrs: {
      "messages": _vm.filteredMessages.filter(function (m) {
        return m.pinned;
      }),
      "selected-message": _vm.selectedMessage,
      "get-user-from-sub": _vm.getUserFromSub,
      "pinned": ""
    },
    on: {
      "click-message": _vm.onClickMessage
    }
  }), _c('MessagesInbox', {
    attrs: {
      "messages": _vm.filteredMessages.filter(function (m) {
        return !m.pinned;
      }),
      "selected-message": _vm.selectedMessage,
      "get-user-from-sub": _vm.getUserFromSub
    },
    on: {
      "click-message": _vm.onClickMessage
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No messages")])])]), _c('div', {
    staticClass: "col-xl-9 col-lg-8 col-md-7"
  }, [_vm.loading ? _c('Spinner') : _vm.showNewMessage ? _c('div', {
    attrs: {
      "data-cy": "new-message"
    }
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content block-content-sm block-content-full bg-body-light"
  }, [_c('div', {
    staticClass: "media py-3"
  }, [_c('div', {
    staticClass: "media-body"
  }, [_c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-sm-7"
  }, [_c('span', {
    staticClass: "font-w600"
  }, [_vm._v("Create a new message")])]), _c('div', {
    staticClass: "col-sm-1"
  }, [_c('button', {
    staticClass: "btn btn-link text-danger fa-lg p-0",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showNewMessage = false;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })])])])])])]), _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content block-content-full"
  }, [_c('FormGroup', {
    attrs: {
      "id": "title",
      "label": "Title",
      "type": "text",
      "error": _vm.validationErrors.title
    },
    model: {
      value: _vm.newMessage.title,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "title", $$v);
      },
      expression: "newMessage.title"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "content",
      "label": "Message",
      "type": "textarea",
      "error": _vm.validationErrors.content
    },
    model: {
      value: _vm.newMessage.content,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "content", $$v);
      },
      expression: "newMessage.content"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "Category",
      "type": _vm.companyCategories.length > 0 ? 'select' : 'text',
      "options": _vm.companyCategories
    },
    model: {
      value: _vm.newMessage.category,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "category", $$v);
      },
      expression: "newMessage.category"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "label": "Type",
      "type": _vm.messageTypes.length > 0 ? 'select' : 'text',
      "options": _vm.messageTypes
    },
    model: {
      value: _vm.newMessage.type,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "type", $$v);
      },
      expression: "newMessage.type"
    }
  }), _vm.newMessage.type === 'action' ? _c('FormGroup', {
    attrs: {
      "id": "dueDate",
      "label": "Due Date",
      "type": "dateTimePicker"
    },
    model: {
      value: _vm.newMessage.dueDate,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "dueDate", $$v);
      },
      expression: "newMessage.dueDate"
    }
  }) : _vm._e(), _vm.accounts.length > 0 ? _c('FormGroup', {
    attrs: {
      "id": "accountIds",
      "label": "Accounts",
      "type": "select-array-list",
      "options": _vm.accountOptions,
      "overflow": ""
    },
    model: {
      value: _vm.newMessage.accountIds,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "accountIds", $$v);
      },
      expression: "newMessage.accountIds"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v(" Attachments")]), _c('FileUpload', {
    attrs: {
      "loading": _vm.loadingAction.upload,
      "file-types": [],
      "multiple-files": true
    },
    on: {
      "reset": function ($event) {
        _vm.newAttachments = null;
      }
    },
    model: {
      value: _vm.newAttachments,
      callback: function ($$v) {
        _vm.newAttachments = $$v;
      },
      expression: "newAttachments"
    }
  })], 1), _c('button', {
    staticClass: "btn btn-primary mt-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickCreate.apply(null, arguments);
      }
    }
  }, [_vm.loadingAction.upload || _vm.loadingAction.create ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Creating...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Create Message")])])], 1)])])]) : _vm.selectedMessage._id && _vm.selectedMessage.replies.length > 0 ? _c('div', {
    staticClass: "block block-rounded block-content"
  }, [_c('div', {
    staticClass: "row align-items-center mb-3"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_c('span', {
    staticClass: "mr-3"
  }, [_vm._v(_vm._s(_vm.selectedMessage.title))]), _c('span', {
    staticClass: "badge badge-info text-capitalize mr-3"
  }, [_vm._v(_vm._s(_vm.selectedMessage.type))]), _c('span', {
    staticClass: "badge badge-success text-capitalize mr-3"
  }, [_vm._v(_vm._s(_vm.selectedMessage.category))]), _vm.selectedMessage.dueDate ? _c('span', {
    staticClass: "badge badge-danger mr-3"
  }, [_c('i', {
    staticClass: "fa fa-clock"
  }), _vm._v(" " + _vm._s(_vm._f("date")(_vm.selectedMessage.dueDate, 'DD/MM/YYYY HH:mm')))]) : _vm._e(), _vm.selectedMessage.status !== 'open' ? _c('span', {
    staticClass: "badge badge-danger text-capitalize mr-3"
  }, [_vm._v("CLOSED")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('span'), _c('span', [_vm.$permissions.write('asset', _vm.parent) ? _c('button', {
    staticClass: "btn mr-3",
    class: {
      'btn-alt-warning': _vm.selectedMessage.pinned,
      'btn-alt-light': !_vm.selectedMessage.pinned
    },
    attrs: {
      "data-cy": "message-edit-btn",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickPinMessage.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-thumb-tack mr-1"
  }), _vm._v(" " + _vm._s(_vm.selectedMessage.pinned ? 'UnPin' : 'Pin') + " ")]) : _vm._e(), _vm.$permissions.write('asset', _vm.parent) ? _c('button', {
    staticClass: "btn btn-alt-light mr-3",
    attrs: {
      "data-cy": "message-edit-btn",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickEditMessage.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil mr-1"
  }), _vm._v(" Edit ")]) : _vm._e(), _vm.$permissions.write('asset', _vm.parent) ? _c('button', {
    staticClass: "btn btn-alt-light mr-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickCloseMessage(_vm.selectedMessage);
      }
    }
  }, [_c('i', {
    staticClass: "fa mr-1",
    class: {
      'fa-eye-slash': _vm.selectedMessage.status === 'open',
      'fa-eye': _vm.selectedMessage.status !== 'open'
    }
  }), _vm._v(" " + _vm._s(_vm.selectedMessage.status === 'open' ? 'Close' : 'Open') + " ")]) : _vm._e(), _vm.$permissions.write('asset', _vm.parent) ? _c('button', {
    staticClass: "btn btn-alt-danger",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickDeleteMessage(_vm.selectedMessage);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash mr-1"
  }), _vm._v(" Delete ")]) : _vm._e()])])])]), _c('div', {
    staticClass: "row align-items-start border-bottom pb-3 mb-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm.reassignUser && _vm.$permissions.write('asset', _vm.parent) ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.$refs.editMessage ? _c('FormGroup', {
    staticClass: "flex-grow-1",
    attrs: {
      "id": "assignedUserSub",
      "value": _vm.selectedMessage.assignedUserSub,
      "label": "Assigned To",
      "placeholder": "Assign to user...",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "disabled": _vm.loadingAction.assignUser,
      "options": _vm.companyUsers
    },
    on: {
      "input": _vm.onChangeAssignedUser
    }
  }) : _vm._e(), _vm.loadingAction.assignUser ? _c('div', {
    staticClass: "ml-2"
  }, [_c('i', {
    staticClass: "fa fa-spin fa-spinner"
  })]) : _vm._e()], 1) : _vm.selectedMessage.assignedUserSub ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('strong', [_vm._v("Assigned to: ")]), _c('span', {
    staticClass: "text-secondary ml-2"
  }, [_vm._v(_vm._s(_vm.getUserFromSub(_vm.selectedMessage.assignedUserSub).name))]), _vm.$permissions.write('asset', _vm.parent) ? _c('a', {
    staticClass: "btn btn-sm btn-primary ml-2",
    class: {
      disabled: _vm.loadingAction.assignUser
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.reassignUser = true;
      }
    }
  }, [_vm._v("Change")]) : _vm._e(), _vm.$permissions.write('asset', _vm.parent) ? _c('a', {
    staticClass: "btn btn-sm btn-alt-warning ml-2",
    class: {
      disabled: _vm.loadingAction.assignUser
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onChangeAssignedUser('');
      }
    }
  }, [_vm.loadingAction.assignUser ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-2"
  }), _vm._v("Unassigning...")]) : _c('span', [_vm._v("Unassign")])]) : _vm._e()]) : _vm.$permissions.write('asset', _vm.parent) ? _c('div', [_c('a', {
    staticClass: "btn btn-alt-warning",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.reassignUser = true;
      }
    }
  }, [_vm._v("Assign to user")])]) : _vm._e()])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_vm.selectedMessage.accounts ? _c('div', [_c('ul', {
    staticClass: "list-group list-group-flush text-right accounts-list"
  }, [_c('span', {
    staticClass: "badge badge-primary mr-2"
  }, [_vm._v("Linked Accounts")]), _vm._l(_vm.selectedMessage.accounts.data, function (account) {
    return _c('li', {
      key: account._id,
      staticClass: "list-group-item"
    }, [_c('router-link', {
      staticClass: "font-w600",
      attrs: {
        "to": {
          name: 'asset-accounts-overview',
          params: {
            id: account.assetId,
            accountId: account._id
          }
        },
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(account.type)) + " - " + _vm._s(account.name) + " [MPAN: " + _vm._s(account.meterPointNumber) + "] ")])], 1);
  }), _vm.selectedMessage.invoice ? _c('li', {
    staticClass: "list-group-item"
  }, [_c('router-link', {
    staticClass: "font-w600",
    attrs: {
      "to": {
        name: 'asset-accounts-overview',
        params: {
          id: this.$route.params.id,
          accountId: _vm.invoice.accountId
        }
      },
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "badge badge-primary mr-2"
  }, [_vm._v("Invoice")]), _vm._v(" " + _vm._s(_vm.selectedMessage.invoice.values.invoiceNumber) + " (" + _vm._s(_vm._f("date")(_vm.selectedMessage.invoice.values.startDate)) + " - " + _vm._s(_vm._f("date")(_vm.selectedMessage.invoice.values.endDate)) + ") ")])], 1) : _vm._e()], 2)]) : _vm._e()])]), _vm.pinnedReplies.length !== 0 ? _c('div', {
    staticClass: "border-left border-right border-top mb-4 bg-light"
  }, [_c('div', {
    staticClass: "mb-0 ml-4 mt-3"
  }, [_c('i', {
    staticClass: "fa fa-thumb-tack mr-1 text-success fa-xl"
  }), _c('small', {
    staticClass: "text-uppercase"
  }, [_vm._v(" pinned replies ")])]), _vm._l(_vm.pinnedReplies, function (reply, replyIdx) {
    return _c('div', {
      key: reply._id,
      class: {
        'border-bottom': replyIdx + 1 !== _vm.pinnedReplies.length
      }
    }, [_c('div', {
      class: {
        'opacity-50': reply.deletedOn
      }
    }, [_c('div', {
      staticClass: "block-content block-content-sm block-content-full"
    }, [_c('div', {
      staticClass: "row align-items-start"
    }, [_c('div', {
      staticClass: "col-sm-7 d-flex align-items-center"
    }, [_vm.getUserFromSub(reply.userSub).picture ? _c('img', {
      staticClass: "img-avatar img-avatar32 mr-4",
      attrs: {
        "src": _vm.getUserFromSub(reply.userSub).picture,
        "alt": ""
      }
    }) : _vm._e(), _c('div', [_c('p', {
      staticClass: "message-title font-size-h5 font-w700 mb-0"
    }, [_vm._v(_vm._s(_vm.getUserFromSub(reply.userSub).name))]), _c('p', {
      staticClass: "message-user font-size-sm text-secondary mb-0"
    }, [_vm._v(_vm._s(_vm.getUserFromSub(reply.userSub).email))])])]), _c('div', {
      staticClass: "col-sm-5 d-flex justify-content-end"
    }, [_c('div', {
      staticClass: "text-right"
    }, [_c('p', {
      staticClass: "font-size-sm font-w500 text-muted mb-1"
    }, [_vm._v(_vm._s(_vm._f("date")(reply.createdAt, 'dddd Do MMM YYYY HH:mm')))]), !reply.deletedOn && _vm.$permissions.write('asset', _vm.parent) ? _c('div', {
      staticClass: "d-flex justify-content-end"
    }, [_c('div', {
      staticClass: "d-flex justify-content-end"
    }, [_c('button', {
      staticClass: "btn btn-text btn-sm",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickPin(reply);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-thumb-tack mr-1"
    }), _vm._v(" Unpin ")])]), reply.userSub === _vm.$auth.user.sub ? _c('div', [!reply.isPinnedEdit ? _c('button', {
      staticClass: "btn btn-text btn-sm text-info",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickEditReply(reply, 'isPinnedEdit');
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pencil mr-1"
    }), _vm._v(" Edit ")]) : _vm._e(), reply.isPinnedEdit ? _c('button', {
      staticClass: "btn btn-alt-success btn-sm",
      attrs: {
        "disabled": reply.isSaving || reply.isDeleting
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickSaveReply(reply, 'isPinnedEdit');
        }
      }
    }, [reply.isSaving ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Saving...")]) : _c('span', [_c('i', {
      staticClass: "fa fa-check mr-1"
    }), _vm._v(" Save")])]) : _vm._e(), _c('button', {
      staticClass: "btn btn-text btn-sm text-danger",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickDeleteReply(reply);
        }
      }
    }, [reply.isDeleting ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Deleting...")]) : _c('span', [_c('i', {
      staticClass: "fa fa-trash mr-1"
    }), _vm._v(" Delete")])])]) : _vm._e()]) : reply.deletedOn ? _c('div', {
      staticClass: "text-muted font-italic",
      attrs: {
        "title": `Deleted on ${reply.deletedOn}`
      }
    }, [_vm._v(" Reply deleted by user ")]) : _vm._e()])])])]), _c('div', {
      staticClass: "block-content message-content"
    }, [reply.isPinnedEdit ? _c('FormGroup', {
      attrs: {
        "id": `reply-${reply._id}`,
        "type": "textarea",
        "label": ""
      },
      model: {
        value: reply.content,
        callback: function ($$v) {
          _vm.$set(reply, "content", $$v);
        },
        expression: "reply.content"
      }
    }) : !reply.deletedOn ? _c('p', {
      staticClass: "mb-4"
    }, [_vm._v(_vm._s(reply.content))]) : _vm._e()], 1), reply.attachments.length > 0 && !reply.deletedOn ? _c('div', {
      staticClass: "block-content block-content-full bg-body-light"
    }, [_c('p', {
      staticClass: "text-muted text-uppercase mb-2"
    }, [_vm._v("Attachments")]), _vm._l(reply.attachments, function (attachment) {
      return _c('div', {
        key: attachment._id
      }, [_c('a', {
        staticClass: "font-size-sm text-muted pt-2",
        attrs: {
          "href": "#"
        },
        on: {
          "click": function ($event) {
            $event.preventDefault();
            return _vm.onClickDownload(reply, attachment);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-paperclip"
      }), _vm._v(" " + _vm._s(attachment.name))])]);
    })], 2) : _vm._e()])]);
  })], 2) : _vm._e(), _vm._l(_vm.selectedMessage.replies, function (reply, replyIdx) {
    return _c('div', {
      key: reply._id,
      staticClass: "block block-rounded",
      class: {
        'border-bottom': replyIdx + 1 !== _vm.selectedMessage.replies.length
      }
    }, [_c('div', {
      class: {
        'opacity-50': reply.deletedOn
      }
    }, [_c('div', {
      staticClass: "block-content block-content-sm block-content-full"
    }, [_c('div', {
      staticClass: "row align-items-start"
    }, [_c('div', {
      staticClass: "col-sm-7 d-flex align-items-center"
    }, [_vm.getUserFromSub(reply.userSub).picture ? _c('img', {
      staticClass: "img-avatar img-avatar32 mr-4",
      attrs: {
        "src": _vm.getUserFromSub(reply.userSub).picture,
        "alt": ""
      }
    }) : _vm._e(), _c('div', [_c('p', {
      staticClass: "message-title font-size-h5 font-w700 mb-0"
    }, [_vm._v(_vm._s(_vm.getUserFromSub(reply.userSub).name))]), _c('p', {
      staticClass: "message-user font-size-sm text-secondary mb-0"
    }, [_vm._v(_vm._s(_vm.getUserFromSub(reply.userSub).email))])])]), _c('div', {
      staticClass: "col-sm-5 d-flex justify-content-end"
    }, [_c('div', {
      staticClass: "text-right"
    }, [_c('p', {
      staticClass: "font-size-sm font-w500 text-muted mb-1"
    }, [_vm._v(_vm._s(_vm._f("date")(reply.createdAt, 'dddd Do MMM YYYY HH:mm')))]), !reply.deletedOn && _vm.$permissions.write('asset', _vm.parent) ? _c('div', {
      staticClass: "d-flex justify-content-end"
    }, [!reply.isEdit && !reply.pinned ? _c('button', {
      staticClass: "btn btn-text btn-sm",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickPin(reply);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-thumb-tack mr-1"
    }), _vm._v(" Pin ")]) : _vm._e(), reply.userSub === _vm.$auth.user.sub ? _c('div', [!reply.isEdit ? _c('button', {
      staticClass: "btn btn-text btn-sm text-info",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickEditReply(reply, 'isEdit');
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pencil mr-1"
    }), _vm._v(" Edit ")]) : _vm._e(), reply.isEdit ? _c('button', {
      staticClass: "btn btn-alt-success btn-sm",
      attrs: {
        "disabled": reply.isSaving || reply.isDeleting
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickSaveReply(reply, 'isEdit');
        }
      }
    }, [reply.isSaving ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Saving...")]) : _c('span', [_c('i', {
      staticClass: "fa fa-check mr-1"
    }), _vm._v(" Save")])]) : _vm._e(), _c('button', {
      staticClass: "btn btn-text btn-sm text-danger",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickDeleteReply(reply);
        }
      }
    }, [reply.isDeleting ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-1"
    }), _vm._v(" Deleting...")]) : _c('span', [_c('i', {
      staticClass: "fa fa-trash mr-1"
    }), _vm._v(" Delete")])])]) : _vm._e()]) : reply.deletedOn ? _c('div', {
      staticClass: "text-muted font-italic",
      attrs: {
        "title": `Deleted on ${reply.deletedOn}`
      }
    }, [_vm._v(" Reply deleted by user ")]) : _vm._e()])])])]), _c('div', {
      staticClass: "block-content message-content"
    }, [reply.isEdit ? _c('FormGroup', {
      attrs: {
        "id": `reply-${reply._id}`,
        "type": "textarea",
        "label": ""
      },
      model: {
        value: reply.content,
        callback: function ($$v) {
          _vm.$set(reply, "content", $$v);
        },
        expression: "reply.content"
      }
    }) : !reply.deletedOn ? _c('p', {
      staticClass: "mb-4"
    }, [_vm._v(_vm._s(reply.content))]) : _vm._e()], 1), reply.attachments.length > 0 && !reply.deletedOn ? _c('div', {
      staticClass: "block-content block-content-full bg-body-light"
    }, [_c('p', {
      staticClass: "text-muted text-uppercase mb-2"
    }, [_vm._v("Attachments")]), _vm._l(reply.attachments, function (attachment) {
      return _c('div', {
        key: attachment._id
      }, [_c('a', {
        staticClass: "font-size-sm text-muted pt-2",
        attrs: {
          "href": "#"
        },
        on: {
          "click": function ($event) {
            $event.preventDefault();
            return _vm.onClickDownload(reply, attachment);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-paperclip"
      }), _vm._v(" " + _vm._s(attachment.name))])]);
    })], 2) : _vm._e()])]);
  }), _vm.$permissions.write('asset', _vm.parent) ? _c('div', [_vm.selectedMessage.status !== 'open' ? _c('div', {
    staticClass: "alert alert-warning font-w600 text-center font-size-h5 mb-3"
  }, [_vm._v(" This message is closed. Sending a reply will re-open the message. ")]) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "content",
      "type": "textarea",
      "placeholder": "Enter a message..."
    },
    model: {
      value: _vm.newMessage.content,
      callback: function ($$v) {
        _vm.$set(_vm.newMessage, "content", $$v);
      },
      expression: "newMessage.content"
    }
  }), _c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-9"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v(" Attachments")]), _c('FileUpload', {
    attrs: {
      "loading": _vm.loadingAction.upload,
      "file-types": [],
      "multiple-files": true
    },
    on: {
      "reset": function ($event) {
        _vm.newAttachments = null;
      }
    },
    model: {
      value: _vm.newAttachments,
      callback: function ($$v) {
        _vm.newAttachments = $$v;
      },
      expression: "newAttachments"
    }
  })], 1)]), _c('div', {
    staticClass: "col-md-3 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mt-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickReply.apply(null, arguments);
      }
    }
  }, [_vm.loadingAction.upload || _vm.loadingAction.reply ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" Sending...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Send Message")])])])])], 1) : _vm._e()], 2) : _vm._e()], 1)]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Message",
      "text": `Please confirm you would like to remove message: <strong>${_vm.modals.remove.title}</strong>`
    },
    on: {
      "close": function ($event) {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemove
    }
  }), _c('ConfirmModal', {
    ref: "editMessage",
    attrs: {
      "id": "edit-message",
      "data-cy": "message-edit",
      "open": !!_vm.modals.edit,
      "title": "Edit Message",
      "text": `Please confirm you would like to edit message: <strong>${_vm.modals.edit.title}</strong>`
    },
    on: {
      "close": function ($event) {
        _vm.modals.edit = false;
      },
      "submit": _vm.onEdit
    }
  }, [!!_vm.modals.edit ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "title",
      "label": "Title",
      "type": "text"
    },
    model: {
      value: _vm.modals.edit.title,
      callback: function ($$v) {
        _vm.$set(_vm.modals.edit, "title", $$v);
      },
      expression: "modals.edit.title"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "label": "Type",
      "type": _vm.messageTypes.length > 0 ? 'select' : 'text',
      "options": _vm.messageTypes
    },
    model: {
      value: _vm.modals.edit.type,
      callback: function ($$v) {
        _vm.$set(_vm.modals.edit, "type", $$v);
      },
      expression: "modals.edit.type"
    }
  }), _vm.modals.edit.type === 'action' ? _c('FormGroup', {
    attrs: {
      "id": "dueDate",
      "label": "Due Date",
      "type": "dateTimePicker"
    },
    model: {
      value: _vm.modals.edit.dueDate,
      callback: function ($$v) {
        _vm.$set(_vm.modals.edit, "dueDate", $$v);
      },
      expression: "modals.edit.dueDate"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "Category",
      "type": _vm.companyCategories.length > 0 ? 'select' : 'text',
      "options": _vm.companyCategories
    },
    model: {
      value: _vm.modals.edit.category,
      callback: function ($$v) {
        _vm.$set(_vm.modals.edit, "category", $$v);
      },
      expression: "modals.edit.category"
    }
  }), _vm.accounts.length > 0 ? _c('FormGroup', {
    attrs: {
      "id": "accountIds",
      "label": "Accounts",
      "type": "select-array-list",
      "options": _vm.accountOptions,
      "overflow": ""
    },
    model: {
      value: _vm.modals.edit.accountIds,
      callback: function ($$v) {
        _vm.$set(_vm.modals.edit, "accountIds", $$v);
      },
      expression: "modals.edit.accountIds"
    }
  }) : _vm._e()], 1) : _vm._e()])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-append"
  }, [_c('span', {
    staticClass: "input-group-text border-0 bg-white"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-search"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "mb-2 text-muted"
  }, [_c('i', {
    staticClass: "fa fa-thumbtack mr-2"
  }), _c('span', {
    staticClass: "font-w600"
  }, [_vm._v("Pinned Messages")])])]);

}]

export { render, staticRenderFns }